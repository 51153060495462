import React from "react";
import "./css/SaveToCSV.css"; // Import the CSS file

const SaveToCSV = ({ data, filename }) => {
  // Helper function to flatten the data and select only the necessary fields
  const flattenData = (data) => {
    return data.map((item) => ({
      name: item.name || "",
      company: item.company || "",
      bio: item.bio ? cleanBio(item.bio) : "", // Clean the bio field
      profile_link: item.profile_link || "",
      profile_image: item.profile_image || "",
      email_formats: item.email_formats ? item.email_formats.join("; ") : "",
      date_added: item.date_added
        ? `${item.date_added.month}/${item.date_added.day}/${item.date_added.year}`
        : "",
      group: item.dataset_name || "",
    }));
  };

  // Helper function to clean the bio field
  const cleanBio = (bio) => {
    // Replace newlines with spaces and remove excessive whitespace
    return bio.replace(/\n/g, " ").replace(/\s+/g, " ").trim();
  };

  // Helper function to convert flattened data to CSV format
  const convertToCSV = (array) => {
    if (array.length === 0) return "";

    const keys = Object.keys(array[0]); // Get the keys from the first object
    const csvRows = [];

    // Add headers as the first row
    csvRows.push(keys.join(","));

    // Loop through the array and create a row for each object
    array.forEach((obj) => {
      const row = keys
        .map((key) => (obj[key] !== null ? `"${obj[key]}"` : ""))
        .join(",");
      csvRows.push(row);
    });

    return csvRows.join("\n");
  };

  // Function to trigger CSV download
  const downloadCSV = () => {
    const flattenedData = flattenData(data);
    const csv = convertToCSV(flattenedData);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <button onClick={downloadCSV} className="export-button">
      Export to CSV
    </button>
  );
};

export default SaveToCSV;

import React from "react";
import "./css/SelectedDataDropdown.css";

const Dropdown = ({ data, handleDropdownChange, currentDatasetSelected }) => {
  return (
    <div className="dropdown-container">
      <h3 className="send-title">Send emails to:</h3>
      <div className="dropdown-wrapper">
        <select
          className="dropdown-selection dropdown-text-gradient"
          value={currentDatasetSelected}
          onChange={handleDropdownChange}
        >
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))
          ) : (
            <option>No datasets available</option>
          )}
        </select>
        {/* Custom Arrow */}
        <div className="dropdown-arrow">▼</div>
      </div>
    </div>
  );
};

export default Dropdown;

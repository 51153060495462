import "./css/TermsOfService.css";

const TermsOfService = () => {
  return (
    <section id="terms-of-service">
      <div className="container">
        <h1 className="title">Terms of Service</h1>
        <h2 className="sub-title">Effective Date: October 10, 2024</h2>

        <p>
          Welcome to Nodes ("we", "us", or "our"). By using our Chrome extension
          and related services, you agree to be bound by the following terms and
          conditions. Please read them carefully before using our extension.
        </p>

        <h3 className="section-title">1. Acceptance of Terms</h3>
        <p>
          By installing and using the Nodes Chrome extension, you agree to
          comply with and be bound by these Terms of Service. If you do not
          agree with any part of these terms, you must not use our extension.
        </p>

        <h3 className="section-title">
          2. User Conduct and Prohibited Activities
        </h3>
        <p>
          You agree not to use our extension to send messages with abusive,
          defamatory, harassing, or malicious intent. We reserve the right to
          monitor the messages sent via our extension and, at our discretion,
          delete your account if any abuse or negative intent is observed.
        </p>

        <p>
          Nodes is not affiliated with LinkedIn, and any interaction you have
          with LinkedIn through our extension is at your own risk. You are
          responsible for ensuring that your use of our extension complies with
          LinkedIn's terms of service. If LinkedIn takes action against your
          account due to the use of Nodes, we are not responsible for any
          consequences, including the suspension or termination of your LinkedIn
          account.
        </p>

        <h3 className="section-title">3. Account Suspension and Termination</h3>
        <p>
          We reserve the right to suspend or terminate your Nodes account if we
          determine that you have violated these Terms of Service or engaged in
          any activity that harms our platform, other users, or third parties.
          This includes, but is not limited to, sending abusive messages or
          misusing the collected LinkedIn information.
        </p>

        <h3 className="section-title">
          4. Risks Associated with Using the Extension
        </h3>
        <p>
          By using the Nodes Chrome extension, you acknowledge that you bear all
          risks associated with the use of the extension. Nodes does not
          guarantee that your LinkedIn account or any other third-party accounts
          will remain unaffected. Use of the extension is at your own
          discretion, and we disclaim all liability for any consequences arising
          from the use of the extension, including but not limited to the
          restriction, suspension, or banning of your LinkedIn account.
        </p>

        <h3 className="section-title">5. Limitation of Liability</h3>
        <p>
          In no event shall Nodes be liable for any direct, indirect,
          incidental, special, or consequential damages arising out of or in any
          way connected with the use of our Chrome extension. This includes any
          loss or damage resulting from suspension or termination of your
          LinkedIn or Nodes account.
        </p>

        <h3 className="section-title">
          6. Modifications to the Terms of Service
        </h3>
        <p>
          We reserve the right to modify these Terms of Service at any time.
          Changes will be posted here with an updated effective date. It is your
          responsibility to review these terms periodically for any updates or
          changes.
        </p>

        <h3 className="section-title">7. Contact Us</h3>
        <p>
          If you have any questions or concerns about these Terms of Service,
          please contact us at:
          <a href="mailto:ansapplication@gmail.com" className="contact-link">
            {" "}
            ansapplication@gmail.com
          </a>
        </p>
      </div>
    </section>
  );
};

export default TermsOfService;

import React from "react";
import { Routes, Route } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// routes
import { AuthContextProvider } from "./Context/AuthContext";
import Landing from "./Pages/Landing";
import Home from "./Pages/Home";
import Signup from "./Pages/Signup";
import Signin from "./Pages/Signin";
import ProtectedRoute from "./Components/ProtectedRoute";
import Companies from "./Pages/Companies";
import ViewProfile from "./Pages/ViewProfile";
import SendingPage from "./Pages/SendingPage";
import AuthorizePage from "./Pages/Authorization";
import PopupOAuthCallback from "./Components/PopupOAuthCallback";
import UserSentEmails from "./Pages/UserSentEmails";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfService from "./Components/TermsOfService";

const App = () => {
  return (
    <>
      <AuthContextProvider>
        <Routes>
          <Route path="/" exact element={<Landing />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/authentication" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />

          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="/authorization" element={<AuthorizePage />} />
          <Route
            path="/user-profile"
            element={
              <ProtectedRoute>
                <ViewProfile />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/popup-oauth-callback"
            element={<PopupOAuthCallback />}
          />
          <Route
            path="/send-emails"
            element={
              <ProtectedRoute>
                <SendingPage />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/user-sent-emails"
            element={
              <ProtectedRoute>
                <UserSentEmails />
              </ProtectedRoute>
            }
          ></Route>
        </Routes>
      </AuthContextProvider>
    </>
  );
};

export default App;

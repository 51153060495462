import React from "react";
import Slider from "react-slick";
import "./css/LandingPageImageCarousel.css"; // Add custom styles if needed

// Import your images
import mainImage from "../images/ss_main.jpg";
import sendEmailsImage from "../images/ss_send_emails.jpg";
import linkedinImage from "../images/ss_w_linkedin.png";

const ImageCarousel = () => {
  // Settings for the react-slick carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500, // Increase the speed to 1500ms for smoother transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable auto-rotation
    autoplaySpeed: 5000, // Set the speed for auto-rotation to 5 seconds
    fade: true, // Enable fading transition instead of slide
    cssEase: "ease-in-out", // Smoother easing function
    arrows: false, // Disable next/prev arrows
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        <div>
          <img src={mainImage} alt="Main" className="carousel-image" />
        </div>
        <div>
          <img
            src={sendEmailsImage}
            alt="Send Emails"
            className="carousel-image"
          />
        </div>
        <div>
          <img
            src={linkedinImage}
            alt="With LinkedIn"
            className="carousel-image"
          />
        </div>
      </Slider>
    </div>
  );
};

export default ImageCarousel;

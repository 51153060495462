import { React, useState, useEffect } from "react";
import { UserAuth } from "../Context/AuthContext";

const UserSentEmails = () => {
  const { user } = UserAuth();

  const [sentEmails, setSentEmails] = useState([]);

  useEffect(() => {
    const fetchSentEmails = async () => {
      if (user && user.uid) {
        try {
          const response = await fetch(
            `http://localhost:8000/get-user-sent-emails?userID=${user.uid}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          console.log(data);

          if (data.success) {
            setSentEmails(data.data);
          }
        } catch (error) {
          console.error("Error fetching sent emails:", error);
        }
      }
    };

    fetchSentEmails();
  }, []);

  return (
    <div>
      <h1>UserSentEmails</h1>
      {sentEmails ? (
        <div>
          <h2>Sent Emails</h2>
          <ul>
            {sentEmails.map((email, index) => (
              <li key={index}>
                <p>{email.dataset_name}</p>
                <p>{email.date_sent}</p>
                {email.messages_sent.map((message, index) => (
                  <>
                    <div>Recipient name: {message.recipient_name}</div>
                    <div>Recipient email: {message.recipient_email}</div>
                    <div>Message Subject: {message.message_subject}</div>
                    <div>Message Body: {message.message_body}</div>
                    <br></br>
                  </>
                ))}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No emails sent</p>
      )}
    </div>
  );
};

export default UserSentEmails;

import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context/AuthContext";
import NavBar from "../Components/NavBar";

const ViewProfile = () => {
  const [scrapedResults, setScrapedResults] = useState([]);
  const { user } = UserAuth();

  useEffect(() => {
    // get user's scraped results from firestore
    const getScrapedResults = async () => {
      try {
        const response = await fetch(
          `http://127.0.0.1:8000/get-user-scraped-results/${user.uid}`
        );
        const data = await response.json();
        console.log(data);

        setScrapedResults(data.data);
      } catch (error) {
        console.log(error);
      }
    };

    getScrapedResults();
  }, []);

  return (
    <>
      <NavBar></NavBar>
      <h1>View Scraped Results</h1>
      {scrapedResults
        ? scrapedResults.map((dataset, index) => (
            <>
              <div key={index}>
                <h1>{dataset.dataset_name}</h1>
                {dataset.dataset_list.map((person, index) => (
                  <>
                    <div key={index}>
                      <p>{person.company}</p>
                      <p>{person.email}</p>
                    </div>
                  </>
                ))}
              </div>
            </>
          ))
        : null}
    </>
  );
};

export default ViewProfile;

// AuthorizePage.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "../Context/AuthContext";

const AuthorizePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = UserAuth();
  const { scrapedData, scrapedDatasetNames } = location.state;

  const [authorized, setAuthorized] = useState(false);
  const [checkedAuthorization, setCheckedAuthorization] = useState(false);

  useEffect(() => {
    const handleMessage = (event) => {
      console.log(
        "Received message from:",
        event.origin,
        "Event data: ",
        event.data
      );

      // Ensure the message is coming from the expected origin (backend)
      // if (event.origin !== "https://api.thenodes.ca") return;

      const { type } = event.data;
      console.log("type:", type);

      if (type === "authorization_complete") {
        console.log("Authorization complete received");
        setAuthorized(true); // Update state to indicate authorization is complete
      }
    };

    // Add event listener for messages from the popup window
    window.addEventListener("message", handleMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // Function to initiate the OAuth2 authorization
  useEffect(() => {
    const initiateAuthorization = async () => {
      if (user.uid && !checkedAuthorization) {
        try {
          const userID = user.uid;

          // Open a popup window that points to the backend's get-authorized endpoint
          const popup = window.open(
            `https://api.thenodes.ca/get-authorized?userID=${userID}`,
            "oauth2-popup",
            "width=600,height=600" // Configure popup window size
          );
          // const popup = window.open(
          //   `http://localhost:8000/get-authorized?userID=${userID}`,
          //   "oauth2-popup",
          //   "width=600,height=600" // Configure popup window size
          // );

          if (!popup) {
            console.error("Failed to open popup window");
            return;
          }

          setCheckedAuthorization(true); // Mark as checked
        } catch (error) {
          console.error("Error initiating authorization:", error);
          setCheckedAuthorization(true); // Mark as checked even on error
        }
      }
    };

    initiateAuthorization();
  }, [user, checkedAuthorization]);

  // Navigate to SendingPage once authorized
  useEffect(() => {
    if (authorized) {
      console.log("Navigating to send-emails page");
      navigate("/home", {
        state: {
          scrapedData,
          scrapedDatasetNames,
        },
      });
    }
  }, [authorized, navigate, scrapedData, scrapedDatasetNames]);

  return (
    <div>
      <h1>Authorization Page</h1>
      {!authorized && <p>Checking authorization...</p>}
      {authorized && <p>Authorized! Redirecting to Sending Page...</p>}
    </div>
  );
};

export default AuthorizePage;

// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { UserAuth } from "../Context/AuthContext";

// const AuthorizePage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { user } = UserAuth();
//   const { scrapedData, scrapedDatasetNames } = location.state;

//   const [authorized, setAuthorized] = useState(false);
//   const [checkedAuthorization, setCheckedAuthorization] = useState(false);

//   useEffect(() => {
//     const checkAuthorization = async () => {
//       if (user.uid && !checkedAuthorization) {
//         try {
//           const userID = user.uid;
//           // Open a popup window for OAuth2 authorization
//           const popup = window.open(
//             "",
//             "oauth2-popup",
//             "width=600,height=600" // Removed 'noopener,noreferrer'
//           );

//           if (!popup) {
//             console.error("Failed to open popup window");
//           }

//           // Make POST request to initiate the OAuth2 flow
//           const response = await fetch("http://localhost:8000/get-authorized", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ userID: userID }), // Send userID in request body
//           });

//           // Check if the response redirects to the OAuth2 consent page
//           const data = await response.json();

//           if (data.authorization_url) {
//             // If the response contains an authorization URL, set it as the popup location
//             popup.location.href = data.authorization_url;
//           }

//           setCheckedAuthorization(true); // Mark as checked
//         } catch (error) {
//           console.error("Error checking authorization:", error);
//           setCheckedAuthorization(true); // Mark as checked even on error
//         }
//       }
//     };

//     checkAuthorization();
//   }, [user, checkedAuthorization]);

//   // Listen for messages from the popup
//   useEffect(() => {
//     const handleMessage = (event) => {
//       console.log("Received message from:", event.origin, event.data);

//       // For security, verify the origin of the message
//       if (event.origin !== "http://localhost:3000") return;

//       const { type } = event.data;

//       if (type === "authorization_complete") {
//         console.log("Authorization complete received");
//         setAuthorized(true);
//       }
//     };

//     window.addEventListener("message", handleMessage);

//     return () => {
//       window.removeEventListener("message", handleMessage);
//     };
//   }, []);

//   // Navigate to SendingPage once authorized
//   useEffect(() => {
//     if (authorized) {
//       navigate("/send-emails", {
//         state: {
//           scrapedData,
//           scrapedDatasetNames,
//         },
//       });
//     }
//   }, [authorized, navigate, scrapedData, scrapedDatasetNames]);

//   return (
//     <div>
//       <h1>Authorization Page</h1>
//       {!authorized && <p>Checking authorization...</p>}
//       {authorized && <p>Authorized! Redirecting to Sending Page...</p>}
//     </div>
//   );
// };

// export default AuthorizePage;

// // AuthorizePage.js
// import React, { useState, useEffect } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import { UserAuth } from "../Context/AuthContext";

// const AuthorizePage = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { user } = UserAuth();
//   const { scrapedData, scrapedDatasetNames, userID } = location.state;

//   const [authorized, setAuthorized] = useState(false);
//   const [checkedAuthorization, setCheckedAuthorization] = useState(false);

//   // Function to handle popup messages
//   useEffect(() => {
//     const handleMessage = (event) => {
//       console.log("Received message from:", event.origin, event.data);

//       // Ensure the message is coming from the expected origin (backend)
//       if (event.origin !== "http://127.0.0.1:8000") return;

//       const { type } = event.data;

//       if (type === "authorization_complete") {
//         console.log("Authorization complete received");
//         setAuthorized(true);
//       }
//     };

//     // Add event listener for messages from the popup window
//     window.addEventListener("message", handleMessage);

//     // Clean up the event listener when the component unmounts
//     return () => {
//       window.removeEventListener("message", handleMessage);
//     };
//   }, []);

//   // Function to check and initiate authorization
//   useEffect(() => {
//     const checkAuthorization = async () => {
//       if (user.uid && !checkedAuthorization) {
//         try {
//           const userID = user.uid;

//           // Open a popup window for OAuth2 authorization
//           const popup = window.open(
//             "", // Open an empty popup window initially
//             "oauth2-popup",
//             "width=600,height=600"
//           );

//           if (!popup) {
//             console.error("Failed to open popup window");
//           }

//           // Make POST request to initiate the OAuth2 flow
//           const response = await fetch("http://localhost:8000/get-authorized", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//             },
//             body: JSON.stringify({ userID: userID }), // Send userID in request body
//           });

//           // Check if the response contains the authorization URL
//           const data = await response.json();

//           if (data.authorization_url) {
//             // If the response contains an authorization URL, set it as the popup location
//             popup.location.href = data.authorization_url;
//           }

//           setCheckedAuthorization(true); // Mark as checked
//         } catch (error) {
//           console.error("Error checking authorization:", error);
//           setCheckedAuthorization(true); // Mark as checked even on error
//         }
//       }
//     };

//     checkAuthorization();
//   }, [user, checkedAuthorization]);

//   // Navigate to SendingPage once authorized
//   useEffect(() => {
//     if (authorized) {
//       navigate("/send-emails", {
//         state: {
//           scrapedData,
//           scrapedDatasetNames,
//         },
//       });
//     }
//   }, [authorized, navigate, scrapedData, scrapedDatasetNames]);

//   return (
//     <div>
//       <h1>Authorization Page</h1>
//       {!authorized && <p>Checking authorization...</p>}
//       {authorized && <p>Authorized! Redirecting to Sending Page...</p>}
//     </div>
//   );
// };

// export default AuthorizePage;

import React, { useEffect, useState } from "react";

import defaultImg from "../images/default-profile.png";
import SaveToCSV from "../Components/SaveToCSV"; // Import the new component

import "./css/ScrapedResultsList.css";

const months_list = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const ResultEntry = ({
  name,
  email,
  added,
  group,
  company,
  profile_link,
  profile_img,
}) => {
  return (
    <div className="result-entry">
      <a
        className="result-info name"
        title={name}
        href={profile_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="profile-img"
          src={profile_img ? profile_img : defaultImg}
          alt="profile"
        ></img>
        <div>{name}</div>
      </a>
      <div className="result-info">
        <select className="email-formats">
          {email.map((email_format, i) => (
            <option
              key={i}
              className="email-option"
              id={`option${i}`}
              title={email_format}
            >
              {email_format}
            </option>
          ))}
        </select>
      </div>
      <div className="result-info">
        {typeof added === "object"
          ? `${months_list[added.month - 1]} ${added.day}, ${added.year}`
          : "---"}
      </div>
      <div className="result-info" title={group}>
        {group}
      </div>
      <div className="result-info" id="company" title={company}>
        {company}
      </div>
    </div>
  );
};

const ScrapedResultsList = ({ scrapedData, onClick }) => {
  const [renderedResults, setRenderResults] = useState([]); // list of scraped results CURRENTLY rendered
  const [resultNum, setResultNum] = useState(0); // number of results CURRENTLY rendered
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  useEffect(() => {
    setResultNum(renderedResults.length);
  }, [renderedResults]);

  useEffect(() => {
    const keys = Object.keys(scrapedData);
    const datasets = [];
    keys.forEach((key) => {
      const dataset = scrapedData[key];

      // add the group name to each result
      dataset.forEach((item) => {
        item.dataset_name = key;
      });
      datasets.push(...dataset);
    });

    setRenderResults(datasets);
  }, [scrapedData]);

  // Filter results based on search query
  const filteredResults = renderedResults
    .filter((result) => {
      // Add null checks with default empty values
      const name = result?.name || '';
      const email_formats = result?.email_formats || [];
      const company = result?.company || '';
      const dataset_name = result?.dataset_name || '';
      
      const emailStr = email_formats.join(" ");
      return (
        name.toLowerCase().includes(searchQuery) ||
        emailStr.toLowerCase().includes(searchQuery) ||
        company.toLowerCase().includes(searchQuery) ||
        dataset_name.toLowerCase().includes(searchQuery)
      );
    })
    .sort((a, b) => {
      // Check if both a.date_added and b.date_added exist
      if (!a.date_added || !b.date_added) {
        return 0; // Treat missing dates as equal
      }

      // If both exist, compare them as dates
      const dateA = new Date(
        a.date_added.year,
        a.date_added.month - 1,
        a.date_added.day
      );
      const dateB = new Date(
        b.date_added.year,
        b.date_added.month - 1,
        b.date_added.day
      );
      return dateB - dateA;
    });

  return (
    <div className="scraped-results-list">
      <div className="information-bar">
        {/* <div>{`total results: ${resultNum}`}</div> */}
        <form className="search" onSubmit={(e) => e.preventDefault()}>
          <input
            placeholder="search by email, name, job title, etc"
            onChange={handleSearch}
          ></input>
        </form>
        <SaveToCSV data={filteredResults} filename="scraped_results" />
      </div>
      <div className="result-container">
        <div className="header-bar">
          <h4>name</h4>
          <h4>email(s)</h4>
          <h4>added</h4>
          <h4>group</h4>
          <h4>company</h4>
        </div>

        <div className="results-list">
          {filteredResults.map((value, index) => (
            <ResultEntry
              key={index}
              name={value.name}
              email={value.email_formats}
              added={value.date_added}
              group={value.dataset_name}
              company={`@${value.company}`}
              profile_link={value.profile_link}
              profile_img={value.profile_image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrapedResultsList;

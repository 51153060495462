import React from "react";
import { Link } from "react-router-dom";
import "./css/NavBar.css"; // Import your NavBar-specific CSS

const NavBar = () => {
  return (
    <nav>
      <div className="navbar-container">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          {/* <li>
            <Link to="/companies">Companies</Link>
          </li> */}
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/authentication">Login/Sign Up</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;

// PopupOAuthCallback.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PopupOAuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    console.log("PopupOAuthCallback mounted");
    const communicateBack = () => {
      // Send a message to the main window indicating authorization is complete
      if (window.opener) {
        window.opener.postMessage(
          { type: "authorization_complete" },
          "http://localhost:3000" // Correct: only the origin
        );
        window.close(); // Close the popup
      }
      //   else {
      //     // If opener is not available, navigate to a fallback page or show an error
      //     navigate("/"); // Redirect to home or show an error message
      //   }
    };

    communicateBack();
  }, []);

  return (
    <div>
      <h1>Completing Authorization...</h1>
      <p>Please wait while we complete your authorization.</p>
    </div>
  );
};

export default PopupOAuthCallback;

import React, { useState, useEffect } from "react";
import Dropdown from "../Components/SelectedDataDropdown";
import { UserAuth } from "../Context/AuthContext";
import SendEmails from "./SendEmails";
import "./css/SendingPage.css";

import defaultImg from "../images/default-profile.png";

const ResultEntry = ({ name, company, profile_link, profile_img, onRemove }) => {
  return (
    <div className="result-entry">
      <button 
        className="remove-button"
        onClick={onRemove}
        title="Remove from list"
      >
        ✕
      </button>
      <a
        className="result-info name"
        title={name}
        href={profile_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="profile-img"
          src={profile_img ? profile_img : defaultImg}
          alt="profile"
        ></img>
        <div>{name}</div>
      </a>
      <div className="result-info company">@{company}</div>
    </div>
  );
};

const SendingPage = ({ scrapedData, datasets_sent, scrapedDatasetNames }) => {
  const { user } = UserAuth();
  const [selectedOption, setSelectedOption] = useState("None");
  const [emailData, setEmailData] = useState([]);
  const [emailsSentSuccessfully, setEmailsSentSuccessfully] = useState(false);
  const [alreadySentWarning, setAlreadySentWarning] = useState(false);

  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [appCode, setAppCode] = useState("");

  const handleDropdownChange = (e) => {
    e.preventDefault();
    const datasetName = e.target.value;
    setSelectedOption(datasetName);

    console.log(datasets_sent);
    // Check if the dataset has already been sent
    console.log(
      "Datasets is: ",
      datasets_sent,
      datasets_sent.includes(datasetName)
    );
    const alreadySent = datasets_sent.includes(datasetName);
    setAlreadySentWarning(alreadySent);

    const selectedDataset = scrapedData[datasetName];

    if (selectedDataset) {
      setEmailData(selectedDataset);
    } else {
      setEmailData([]);
    }
  };

  const handleRemoveRecipient = (indexToRemove) => {
    setEmailData(currentData => {
      const removedPerson = currentData[indexToRemove];
      const newData = currentData.filter((_, index) => index !== indexToRemove);
      
      console.log('Removed:', {
        name: removedPerson.name,
        company: removedPerson.company,
        email: removedPerson.email_formats[0]
      });
      console.log('Recipients remaining:', newData.length);
      
      return newData;
    });
  };

  useEffect(() => {
    if (selectedOption && scrapedData) {
      const selectedDataset = scrapedData[selectedOption];
      if (selectedDataset) {
        setEmailData(selectedDataset);
      }
    }
  }, [selectedOption, scrapedData]);

  const handleSendEmails = async () => {
    const userEmailDetails = {
      userID: user.uid,
      emailData: emailData.map(
        ({
          name,
          company,
          email_formats,
          date_added,
          profile_image,
          profile_link,
        }) => ({
          name,
          company,
          email_formats,
          date_added,
          profile_image,
          profile_link,
        })
      ),
      message,
      subject,
      datasetName: selectedOption,
      app_code: appCode,
    };

    console.log(userEmailDetails);

    try {
      // const response = await fetch("http://127.0.0.1:8000/send-emails", {
      const response = await fetch("https://api.thenodes.ca/send-emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userEmailDetails),
      });
      const data = await response.json();
      console.log(data);

      if (data.status === "success") {
        setEmailsSentSuccessfully(true);
        // Show success message
        const successMessage = document.createElement("div");
        successMessage.className = "success-message";
        successMessage.textContent = "Emails sent successfully!";
        document.body.appendChild(successMessage);

        // Remove success message after 3 seconds
        setTimeout(() => {
          successMessage.classList.add("fade-out");
          setTimeout(() => {
            document.body.removeChild(successMessage);
          }, 500);
        }, 3000);
      } else {
        throw new Error(data.detail || "Failed to send emails");
      }
    } catch (e) {
      console.log(e);
      // Show error message
      const errorMessage = document.createElement("div");
      errorMessage.className = "error-message";
      errorMessage.textContent =
        e.message || "Failed to send emails. Please try again.";
      document.body.appendChild(errorMessage);

      // Remove error message after 3 seconds
      setTimeout(() => {
        errorMessage.classList.add("fade-out");
        setTimeout(() => {
          document.body.removeChild(errorMessage);
        }, 500);
      }, 3000);
    }
  };

  return (
    <div id="sending-page">
      <Dropdown
        data={scrapedDatasetNames}
        currentDatasetSelected={selectedOption}
        handleDropdownChange={handleDropdownChange}
      />

      {alreadySentWarning && (
        <div className="warning-message">
          Warning: You have already sent emails to this dataset. Are you sure
          you want to send again?
        </div>
      )}

      <div className="send-emails-portion-container">
        <SendEmails
          setMessage={setMessage}
          setSubject={setSubject}
          subject={subject}
          message={message}
          appCode={appCode}
          setAppCode={setAppCode}
          handleSendEmails={handleSendEmails}
        />

        <div className="recipient-container">
          <div className="selected-recipients-list">
            <h3>Selected Recipients:</h3>
            {emailData.map((value, index) => (
              <ResultEntry
                key={index}
                name={value.name}
                company={value.company}
                profile_link={value.profile_link}
                profile_img={value.profile_image}
                onRemove={() => handleRemoveRecipient(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendingPage;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../Context/AuthContext";
import "./css/SignUp.css";
import logo from "../images/logo_with_nodes.png";
import TermsOfService from "../Components/TermsOfService";
import GoogleButton from "react-google-button";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const { createUser, googleSignIn } = UserAuth();
  const navigate = useNavigate();

  const isGmailAddress = (email) => {
    return email.toLowerCase().endsWith("@gmail.com");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isGmailAddress(email)) {
      setError("Please use a Gmail address to sign up.");
      return;
    }

    if (!termsAccepted) {
      setError("You must agree to the Terms of Service before signing up.");
      return;
    }

    try {
      const success = await createUser(email, password);
      if (success === true) {
        navigate("/home");
      } else {
        setError(success);
      }
    } catch (e) {
      setError(e.message);
      console.log(e.message);
    }
  };

  const handleGoogleSignUp = async () => {
    if (!termsAccepted) {
      setError("You must agree to the Terms of Service before signing up.");
      return;
    }

    try {
      const success = await googleSignIn();
      if (success === true) {
        navigate("/home");
      } else {
        setError(success);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="auth-container">
      <img src={logo} alt="Logo" className="logo" />
      <div className="auth-card">
        <div>
          <h1 className="auth-title">Sign Up</h1>
          <p className="auth-subtitle">
            Already have an account? <Link to="/authentication">Sign in.</Link>
          </p>
        </div>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit} className="auth-form">
          <div>
            <label className="auth-label">Email Address</label>
            <input
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              className="auth-input"
              placeholder="Enter your Gmail"
              required
            />
          </div>
          <div>
            <label className="auth-label">Password</label>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              className="auth-input"
              placeholder="Enter your password"
              required
            />
          </div>

          <div className="terms-container">
            <input
              type="checkbox"
              id="terms-checkbox"
              onChange={(e) => setTermsAccepted(e.target.checked)}
              required
            />
            <label htmlFor="terms-checkbox" className="terms-label">
              I agree to the
            </label>
            <span className="terms-link" onClick={() => setShowTerms(true)}>
              Terms of Service
            </span>
          </div>

          <button type="submit" className="auth-button">
            Sign Up
          </button>
        </form>
        <GoogleButton
          onClick={handleGoogleSignUp}
          label="Sign up with Google"
          type="light"
          className="google-btn"
        />
      </div>

      <div className="privacy-link">
        <p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </div>

      {showTerms && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button
              className="close-button"
              onClick={() => setShowTerms(false)}
            >
              &times;
            </button>
            <TermsOfService />
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;

import React from "react";
import NavBar from "../Components/NavBar";
import ImageCarousel from "../Components/LandingPageImageCarousel"; // Import the carousel
import "./css/Landing.css"; // Import the Landing page CSS
import { Link } from "react-router-dom";

const Landing = () => {
  return (
    <section id="Landing">
      <NavBar />
      {/* Apply gradient and larger size to h1 */}
      <h1 className="landing-title text-gradient">
        Unleash your Networking Potential
      </h1>
      <ImageCarousel /> {/* Add the ImageCarousel component here */}
      {/* Terms and Privacy links side by side */}
      <div className="bottom-left">
        <Link className="privacy-link" to="/terms-of-service">
          Terms of Service
        </Link>
        <Link to="/privacy-policy" className="privacy-link">
          Privacy Policy
        </Link>
      </div>
    </section>
  );
};

export default Landing;

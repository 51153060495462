import React, { useState, useEffect } from "react";
import NavBar from "../Components/NavBar";
import { UserAuth } from "../Context/AuthContext";

const Companies = () => {
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getCompanies = async () => {
      try {
        const response = await fetch("https://api.thenodes.ca/getCompanies");
        const companies = await response.json();

        console.log(companies.data);
        setCompanies(companies.data);
      } catch (e) {
        console.log(e);
      }
    };

    getCompanies();
  }, []);

  return (
    <>
      <NavBar></NavBar>
      <h1>Companies we offer outreach to!</h1>
      {companies ? (
        companies.map(
          (company, index) => (
            // row styling
            <div key={index}>
              <h3>{company.name}</h3>
              {company.email_formats.map((format, index) => (
                <div key={index}>
                  <p>{format.email_format}</p>
                  <p>{format.accuracy}%</p>
                </div>
              ))}
            </div>
          ) // Add closing tag for the div element
        )
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default Companies;
